import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';

const CART_KEY = 'cartItems';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    constructor(
        private toastr: ToastrService
    ) { }

    // ************************************************* CARRO ***********************************************************

    async addToCart(item: any) {

        try {

            item[0].count = 1;

            let result: any = localStorage.getItem(CART_KEY);

            if (result) {

                result = JSON.parse(result);

                const index = result.findIndex((x: any) => x.id === item[0].id);

                if (index === -1) {

                    result.push(item[0]);

                    this.toastr.success('Artículo adicionado al carrito', 'LAPASTELIDOG',{ positionClass: 'toast-bottom-right'});

                    return localStorage.setItem(CART_KEY, JSON.stringify(result));


                } else {

                    const prevQuantity = Number(result[index].count);

                    item[0].count = prevQuantity + Number(item[0].count);

                    result[index].count = item[0].count;

                    this.toastr.success('Artículo adicionado al carrito', 'LAPASTELIDOG', { positionClass: 'toast-bottom-right'});

                    return localStorage.setItem(CART_KEY, JSON.stringify(result));

                }


            } else {

                this.toastr.success('Artículo adicionado al carrito', 'LAPASTELIDOG', { positionClass: 'toast-bottom-right'} );

                return localStorage.setItem(CART_KEY, JSON.stringify(item));

            }


        } catch (error) {
            console.log(error);
        }


    }

    async substracFromCart(item: any) {

        item[0].count = 1;

        let result: any = localStorage.getItem(CART_KEY);

        if (result) {

            result = JSON.parse(result);

            const index = result.findIndex((x: any) => x.id === item.id);

            if (index === -1) {

                // result.push(item);

                // return this.storage.set(CART_KEY, result);

            } else {

                const prevQuantity = Number(result[index].count);

                item[0].count = (Number(prevQuantity) - Number(item[0].count));

                result[index].count = item[0].count;

                return localStorage.setItem(CART_KEY, JSON.stringify(result));

            }


        } else {

            return localStorage.setItem(CART_KEY, JSON.stringify(item));

        }

    }

    async updateCart(product: any) {

        let result: any = localStorage.getItem(CART_KEY);

        if (result) {

            result = JSON.parse(result);

            const index = result.findIndex((x: any) => x.product_id + x.tipo === product.product_id + product.tipo);
            if (this.containsObject(product, result)) {
                result.splice(index, 1, product);

                return localStorage.setItem(CART_KEY, JSON.stringify(result));
            }
        } else {

            return localStorage.setItem(CART_KEY, JSON.stringify(product));
        }

    }

    async removeFromCart(product: any) {

        let result: any = localStorage.getItem(CART_KEY);

        if (result) {

            result = JSON.parse(result);

            const index = result.findIndex((x: any) => x.id === product);
            result.splice(index, 1);

            this.toastr.error('Producto eliminado del carrito','LAPASTELIDOG');

            return localStorage.setItem(CART_KEY, JSON.stringify(result));


        }


    }

    removeAllCartItems() {


        const result = localStorage.removeItem(CART_KEY);

        localStorage.clear()

        return result;

    }

    containsObject(obj: any, list: any): boolean {

        if (!list.length) {
            return false;
        }

        if (obj == null) {
            return false;
        }
        for (let i = 0; i < list.length; i++) {
            if (list[i].product_id === obj.product_id) {
                return true;
            }
        }
        return false;
    }

    getCartItems(): any {

        let result: any = localStorage.getItem(CART_KEY);

        if (result) {

            result = JSON.parse(result);

            return result;
        } else {
            return [];
        }

    }

    getCountItems() {

        let result: any = localStorage.getItem(CART_KEY);

        if (result) {

            result = JSON.parse(result);

            if (!result.length) {
                return 0;
            }
            let cant = 0;
            for (let i = 0; i < result.length; i++) {
                // cant = Number(cant) + (Number(result[i].valor) * (Number(result[i].count)));
                cant += Number(result[i].count)
            }
            return cant;
        } else {
            return 0;
        }

    }

    getTotalItems() {

        let result: any = localStorage.getItem(CART_KEY);

        if (result) {

            result = JSON.parse(result);

            if (!result.length) {
                return 0;
            }
            let cant = 0;
            for (let i = 0; i < result.length; i++) {
                cant = Number(cant) + (Number(result[i].valor) * (Number(result[i].count)));
            }
            return cant;
        } else {
            return 0;
        }

    }

    async showAlert(title: string, subtitle: string) {

        Swal.fire({
            icon: 'info',
            title: title,
            text: subtitle,
            showConfirmButton: true,
            timer: 1500
        });

    }

}
