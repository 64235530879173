<!-- Page Header Start -->
<section class="section hero has-bg-image" id="home" aria-label="home" style="background-image: url('./assets/images/banner6.jpg'); background-repeat: no-repeat;
  background-size: 100% 100%;background-position: top; /* Center the image */">


<br><br><br>
<!-- Page Header Start -->
  <div class="container-fluid mb-5 position-relative overlay-bottom">
    <div class="d-flex flex-column align-items-center justify-content-center pt-0 pt-lg-5" style="min-height: 200px">
      <h3 class="display-4 mb-3 mt-0 mt-lg-5 text-white title-form text-uppercase">Carro de compras</h3>
      <div class="d-inline-flex mb-lg-5">
        <!-- <p class="m-0 text-white"><a class="text-white" href="">Carro</a></p>
            <p class="m-0 text-white px-2">/</p>
            <p class="m-0 text-white">Carro de compras</p> -->
      </div>
    </div>
  </div>
</section>
<!-- Page Header End -->

<ul class="show-div shopping-cart" *ngIf='!products.length'>
  <span class="text-center fs-32 --fw-700">Su carrito de compras está vacío.</span>
</ul>


<!-- Contact Start -->
<div class="container-fluid pt-5" style="font-family: Mulish;" *ngIf='products.length > 0'>
  <div class="container">
    <!-- <div class="section-title"> -->
    <h4 class="text-primary text-uppercase titulo fs-24" style="letter-spacing: 5px;">Datos del comprador</h4>

    <div class="col-md-12 p-0 pb-5">
      <div class="contact-form">

        <div class="mt-5">
          <div class="row">
            <div class="col-md-8">

              <form class="p-3" [formGroup]="todoForm">
                <div class="mb-3">
                  <label for="nombreCompleto" class="form-label titulo">Nombre del PerriPadre</label>
                  <input type="text" class="form-control" id="nombre" formControlName="nombre" required>
                  <div *ngIf="f['nombre'].touched && f['nombre'].invalid" class="alert alert-danger">
                    <div *ngIf="f['nombre'].errors && f['nombre'].errors['required']">Nombre es
                      requerido.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-sm-6">
                    <label for="mascota" class="form-label titulo">Nombre del peludo</label>
                    <input type="text" class="form-control" id="mascota" formControlName="mascota"
                      required>
                    <div *ngIf="f['mascota'].touched && f['mascota'].invalid" class="alert alert-danger">
                      <div *ngIf="f['mascota'].errors && f['mascota'].errors['required']">Nombre de la mascota es
                        requerido.
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label for="cumple" class="form-label titulo">Años que cumple</label>
                    <input type="number" class="form-control" id="cumple" formControlName="cumple" required>
                    <div *ngIf="f['cumple'].touched && f['cumple'].invalid" class="alert alert-danger">
                      <div *ngIf="f['cumple'].errors && f['cumple'].errors['required']">el numero de años de la mascota es
                        requerido.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="direccion" class="form-label titulo">Dirección</label>
                  <input type="text" class="form-control" formControlName="direccion" id="direccion" required>
                  <div *ngIf="f['direccion'].touched && f['direccion'].invalid" class="alert alert-danger">
                    <div *ngIf="f['direccion'].errors && f['direccion'].errors['required']">
                      La dirección es requerida.
                    </div>
                    <div *ngIf="f['direccion'].errors && f['direccion'].errors['minLength']">
                      Ingresa una dirección válida.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-sm-6">
                    <label for="ciudad" class="form-label titulo">Ciudad</label>
                    <select class="custom-select fs-18" id="ciudad" formControlName="ciudad" (change)="setCiudad($event)" required>
                      <option selected value="">Elige una ciudad...</option>
                      <option value="Medellin">Medellín</option>
                      <option value="Bello">Bello</option>
                      <option value="Envigado">Envigado</option>
                      <option value="Sabaneta">Sabaneta</option>
                      <option value="Itagui">Itagui</option>
                      <option value="Caldas">Caldas</option>
                      <option value="La Estrella">La Estrella</option>
                      <option value="Copacabana">Copacabana</option>
                      <option value="sanAntonio">SAN ANTONIO DE PRADO (Corregimiento)</option>
                      <option value="sanCristobal">SAN CRISTOBAL (Urbano)</option>
                      <!-- Agrega más departamentos según sea necesario -->
                    </select>
                    <div *ngIf="f['ciudad'].touched && f['ciudad'].invalid" class="alert alert-danger">
                      <div *ngIf="f['ciudad'].errors && f['ciudad'].errors['required']">
                        La ciudad es requerida.
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label for="telefono" class="form-label titulo">Celular</label>
                    <input type="tel" class="form-control" id="celular" formControlName="celular" required>
                    <div *ngIf="f['celular'].touched && f['celular'].invalid" class="alert alert-danger">
                      <div *ngIf="f['celular'].errors && f['celular'].errors['required']">
                        El celular es requerido.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email" class="form-label titulo">Email</label>
                  <input type="text" class="form-control" id="email" formControlName="email" required>
                  <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                    <div *ngIf="f['email'].errors && f['email'].errors['required']">
                      El email es requerido.
                    </div>
                    <div *ngIf="f['email'].errors && f['email'].errors['email']">
                      Email no válido.
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="fecha" class="form-label titulo">Fecha entrega</label>
                  <input type="date" class="form-control" id="fecha" formControlName="fecha" (change)="onChangeDate($event)" required>
                  <div *ngIf="f['fecha'].touched && f['fecha'].invalid" class="alert alert-danger">
                    <div *ngIf="f['fecha'].errors && f['fecha'].errors['required']">
                      La fecha es requerida.
                    </div>
                  </div>
                </div>

                <!-- <button type="submit" class="btn btn-primary">Realizar Pedido</button> -->
              </form>

              <div>
                <p class="fc-danger p-3 text-center fs-14">Email y Teléfono son datos para contáctarte por el pedido, no se usan para ningun otro propósito.</p>
                <p class="fc-danger p-3 text-center fs-14">Despachos sólo para el áerea metropolitana del Valle de Aburrá (Antioquia - Colombia)</p>
              </div>


            </div>
            <div class="col-md-4">


              <div class="textForm titulo fs-20 fc-primary" color="primary">Resumen de la cuenta</div>

              <!-- Tabla para mostrar productos comprados -->
              <table class="table mt-4">
                <thead>
                  <tr class="titulo">
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of products">
                    <td style="font-size: 13px;">{{product?.nombre}}</td>
                    <td class="text-center">
                      <!-- <input type="button" (click)="restar(product)" value="-" class="minus"> -->
                      {{product?.count}}
                      <!-- <input type="button" (click)="sumar(product)" value="+" class="plus"> -->
                    </td>
                    <td>{{product?.valor * product?.count | currency
                      :'USD':'symbol-narrow':'2.0-0'
                      }} </td>
                  </tr>
                  <!-- Agrega más filas para otros productos -->
                </tbody>
              </table>

              <table class="table" style="width: 100%;">
                <thead>
                  <tr>
                    <td class="text-start textoForm">Total Unidades Pedido</td>
                    <td class="text-end textoForm">{{n1}}</td>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td class="text-left textForm --fw-700 fc-black">Subtotal</td>
                    <td class="text-right textForm --fw-700 fc-black">{{total | currency
                      :'USD':'symbol-narrow':'2.0-0' }}</td>
                  </tr>

                  <tr>
                    <td class="text-left textoForm">+ Envío</td>
                    <td *ngIf="flete > 0" class="text-right textoForm">
                      {{flete | currency :'USD':'symbol-narrow':'2.0-0' }}</td>
                      <td *ngIf="flete == 0" class="text-right fs-14 fc-danger">
                        Selecciona ciudad</td>
                  </tr>
                  <!--
                                    <tr *ngIf="recargo > 0">
                                        <td class="text-left textoForm">+ Recargo</td>
                                        <td class="text-right textoForm">{{recargo | currency
                                            :'USD':'symbol-narrow':'2.0-0' }}</td>
                                    </tr> -->
                  <tr>
                    <td class="text-left textForm titulo fc-primary" style="font-weight: 900;">Total</td>
                    <td class="text-right textForm fc-primary" style="font-weight: 900;">{{totalCalculado | currency
                      :'USD':'symbol-narrow':'2.0-0' }}</td>
                  </tr>
                  <br><br>
                </tfoot>
              </table>

              <div class="text-center titulo">

                <button class="btn btn-success w-100" [disabled]="todoForm.invalid" type="submit" (click)="whatsapp()">
                  Enviar a Whatsapp
                </button>

                <p class="w-100">ó</p>

                <button class="btn btn-dark w-100" [disabled]="todoForm.invalid || isLoading" type="submit"
                  (click)="pagarW()">
                  <img *ngIf="isLoading" style="width : 24px" src="./../../../../assets/img/spinner.gif" />&nbsp;
                  IR A PAGAR...
                </button>

                <br><br>

                <div class="textoWompi text-center">

                  <a href="https://wompi.co/" target="_blank"
                    style="text-align: center; margin: auto;text-align: -webkit-center;"><img class="textoWompi"
                      style="width:100px; text-align: center;text-align: -webkit-center;"
                      src="https://wompi.com/assets/img/home/logo-wompi.svg" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <!-- <a href="https://wompi.co/" target="_blank"><img style="width:100px; text-align: center;"
                                      src="https://wompi.co/wp-content/themes/wp-theme-wompi/dist/images/footer/una-solucion-bancolombia_1e5f8904.svg" /></a> -->

                  <p class="fc-primary fs-14"><a href="https://wompi.co/" target="_blank">Pagos
                      realizados
                      con
                      <strong>Wompi</strong> de Bancolombia</a></p>
                </div>
                <!-- <p class="ion-text-center">ó</p>

                                <ion-button [disabled]="!submitted && todo.invalid" color="success" size="large" expand="full"
                                  shape="round" type="submit" (click)="whatsapp()">
                                  PIDE POR WHATSAPP
                                </ion-button> -->
              </div>
            </div>
          </div>
        </div>
        <!-- </ng-template> -->


        <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"
                    [template]="customLoadingTemplate">
                </ngx-loading> -->

      </div>
    </div>

  </div>
</div>

<br><br>

<!-- About Start -->
<div id="about" class="container-fluid pb-5" data-aos="flip-left">
  <div class="container" data-aos="zoom-in" data-aos-duration="2000">

    <h2 class="h2 section-title">
      <span class="span">Nuestro</span> compromiso
    </h2>

    <div class="row">
      <div class="col-lg-4 py-0 py-lg-5" data-aos="zoom-in-down" data-aos-duration="1500">
        <h1 class="mb-3 text-especial">Saludable</h1>
        <h5 class="mb-3 text-para">Somos una marca que busca la felicidad de los peduditos y sus perripapás, ademas
          de ofrecer un producto con 0% de colorantes y conservantes</h5>
        <p class="fs-18">En cada uno de nuestros productos se destaca la dedicación y el cuidado que ponemos para
          que tengas toda
          la felicidad en tus celebraciones.</p>
        <p class="fs-18">Por eso tenemos plena confianza en que tendrás una experiencia única y llena
          de alegría, sabor y salud.</p>
        <!-- <a href="" class="btn btn-secondary font-weight-bold py-2 px-4 mt-2">Learn More</a> -->
      </div>
      <div class="col-lg-4 py-5 py-lg-0" style="min-height: 500px;">
        <div class="position-relative h-100">
          <img class="position-absolute w-100 h-100" src="assets/images/dog4.png" style="object-fit: cover;">
        </div>
      </div>
      <div class="col-lg-4 py-0 py-lg-5" data-aos="fade-left">
        <h1 class="mb-3 text-especial">Artesanal</h1>
        <h5 class="mb-3 text-para">Horneamos nuestros productos el mismo dia de la entrega para garantizar su
          calidad.</h5>
        <p class="fs-18">En La Pastelidog nos caracterizamos por nuestra pasión por la calidad, el
          compromiso por el constante trabajo por la sostenibilidad ambiental y
          protección de los peluditos. Es por eso que nuestros productos se producen
          el mismo dia de las entregas, garantizando la calidad y conservación.</p>
        <!-- <h5 class="mb-3"><i class="fa fa-check text-primary mr-3"></i>Lorem ipsum dolor sit amet</h5>
              <h5 class="mb-3"><i class="fa fa-check text-primary mr-3"></i>Lorem ipsum dolor sit amet</h5>
              <h5 class="mb-3"><i class="fa fa-check text-primary mr-3"></i>Lorem ipsum dolor sit amet</h5>
              <a href="" class="btn btn-primary font-weight-bold py-2 px-4 mt-2">Learn More</a> -->
      </div>
    </div>
  </div>
</div>
<!-- About End -->

<!-- Contact End -->
