<section class="footer-area pt-100 pb-70" style="background-image: url('./assets/images/footer-bg.jpg')">

  <!-- <div id="contact" class="container-fluid footer text-white mt-5 pt-5 px-0 position-relative overlay-top">
        <div class="row mx-0 pt-5 px-sm-3 px-lg-5 mt-4">
            <div class="col-lg-4 col-md-6 mb-5">
                <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Contácto</h4>
                <p><i class="fa fa-map-marker-alt mr-2"></i>Medellín, Colombia</p>
                <p><i class="fa fa-phone-alt mr-2"></i>+57 300-332-5427</p>
                <p class="m-0"><i class="fa fa-envelope mr-2"></i>cafetodorado&#64;gmail.com</p>
            </div>
            <div class="col-lg-4 col-md-6 mb-5">
                <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Síguenos</h4>
                <p>En nuestras redes sociales:</p>
                <div class="d-flex justify-content-start">
                        <a class="btn btn-lg btn-outline-light btn-lg-square" href="https://www.instagram.com/cafetodorado"
                        target="_blank"><i class="fab fa-instagram"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-5">
                <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Infórmate</h4>
                <p>Inscribe tu correo para mantenerte informado.</p>
                <div class="w-100">
                    <div class="input-group">
                        <input type="text" class="form-control border-light" style="padding: 25px;"
                            placeholder="Your Email">
                        <div class="input-group-append">
                            <button class="btn btn-primary font-weight-bold px-3">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid text-center text-white border-top mt-4 py-4 px-sm-3 px-md-5"
            style="border-color: rgba(256, 256, 256, .1) !important;">
            <p class="mb-2 text-white">Copyright &copy; <a class="font-weight-bold" href="#">cafetodorado.com</a>.
                All
                Rights
                Reserved.</p>
        </div>
    </div> -->

  <div class="footer-top section">
    <div class="container">

      <div class="footer-brand">

        <div class="logo">
          <a (click)="goToHome()">
            <img class="imageHeader" src="/assets/images/logoH.png" width="300">
          </a>
        </div>

        <p class="footer-text">
          Tortas artesanales para perros<br><br>
          🎂 Expertos en nutrición canina 💙 <br>
          🎂 0% colorantes - preservantes 🎉🐶 <br>
          🐶 Celebramos cada día de la vida 🎉
        </p>

        <ul class="contact-list mt-5">

          <li class="contact-item">
            <!-- <ion-icon name="location-outline" aria-hidden="true"></ion-icon> -->

            <address class="address">
              Medellín, Colombia
            </address>
          </li>

          <li class="contact-item">
            <!-- <ion-icon name="call-outline" aria-hidden="true"></ion-icon> -->

            <a href="tel:+16234567891011" class="contact-link">(+57) 320-832-3608</a>
          </li>

        </ul>

        <ul class="social-list mt-4">

          <li>
            <a href="https://www.facebook.com/lapastelidog" class="social-link fab fa-facebook cu-pointer"
              style="background: #3f60d7;color: white; padding: 10px;" target="_blank">
              <!-- <a class="fab fa-facebook" ></a> -->
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/lapastelidog" class="social-link fab fa-instagram cu-pointer"
              style="background: #b631bd;color: white; padding: 10px;" target="_blank">
              <!-- <a class="" ></a> -->
            </a>
          </li>
          <!--
          <li>
            <a href="#" class="social-link" style="background: #39d639;color: white; padding: 10px;">
              <a class="fab fa-tiktok" ></a>
            </a>
          </li> -->

          <li>
            <a href="https://wa.me/573208323608/?text=%C2%A1Hola%20lapastelidog!%0D%0A%0D%0AQuiero%20informaci%C3%B3n%20sobre%0D%0A%0D%0A"
              class="social-link fab fa-whatsapp cu-pointer" style="background: #39d639;color: white; padding: 10px;"
              target="_blank">
              <!-- <a class="fab fa-whatsapp" ></a> -->
            </a>
          </li>

        </ul>

      </div>

      <ul class="footer-list" style="margin-top: 100px;">

        <li>
          <p class="footer-list-title">Nosotros</p>
        </li>

        <li class="nav-item" style="cursor: pointer;">
          <a href="https://www.rappi.com.co/tiendas/900102034-la-pastelidog-ecommerce-mt" style="cursor: pointer;"
            class="footer-link" target="_blank">Pedidos por RAPPI <img src="../../../assets/images/rappi.png"
              alt="rappi" style="max-height: 50px;"> </a>
        </li>

        <li class="nav-item" style="cursor: pointer;"><a (click)="showSection('#nutritips')" class="nav-link pl-0">Nutri
            Tips</a></li>

        <li class="nav-item" style="cursor: pointer;"><a (click)="showSection('#faqs')" class="nav-link pl-0">FAQs</a>
        </li>

      </ul>

      <ul class="footer-list" style="margin-top: 100px;">

        <li>
          <p class="footer-list-title">Información</p>
        </li>

        <li>
          <a href="#" class="footer-link">Política de privacidad</a>
        </li>

        <li>
          <a href="#" class="footer-link">Terminos del Servicio</a>
        </li>

      </ul>

      <ul class="footer-list" style="margin-top: 100px;">

        <li>
          <p class="footer-list-title">Productos</p>
        </li>

        <li>
          <a href="#" class="footer-link">Fiestas personalizadas</a>
        </li>

        <li>
          <a href="#" class="footer-link">Tortas Saludables</a>
        </li>

        <li>
          <a href="#" class="footer-link">Cupcakes</a>
        </li>

        <li>
          <a href="#" class="footer-link">Kits de cumpleaños</a>
        </li>

      </ul>

    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">

      <p class="copyright fc-primary">
        &copy; 2025 Made with ♥ by <a href="https://teaminformatico.com" target="_blank" class="copyright-link">teaminformatico.</a>
      </p>

      <img src="./assets/images/payment2.png" loading="lazy" alt="payment method" class="img">

    </div>
  </div>


</section>

<div class="go-top">
  <i class="fa fa-chevron-up"></i>
  <i class="fa fa-chevron-up"></i>
</div>
