import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

declare var WidgetCheckout: any;

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  url = 'https://us-central1-lapastelidog1.cloudfunctions.net/api';


  version = 'Versión 0.25.0309';

  esPruebas = false;

  private eventCarro = new Subject<any>();

  constructor(
    // private storage: Storage,
    private httpClient: HttpClient
  ) {
  }

  publishSomeData(data: any) {
    this.eventCarro.next(data);
  }

  getObservable(): Subject<any> {
    return this.eventCarro;
  }



  // ************************************************* VERSION ***********************************************************

  getVersion() {
    return this.version;
  }

  // ***************************************** VARIABLES EN STORAGE ******************************************************

  setVariable(variable: string, valor: any) {
    localStorage.setItem(variable, JSON.stringify(valor));
  }

  getVariable(variable: string) {
    const res:any = localStorage.getItem(variable);
    return JSON.parse(res);
  }

  deleteVariable(variable: string) {
    return localStorage.removeItem(variable);
  }

  // ***************************************** POSTS ******************************************************

  getPost(id: any) {
    return this.httpClient.get(this.url + '/post/' + id);
  }

  get3LastPost() {
    return this.httpClient.get(this.url + '/post/three');
  }

  getAllPost() {
    return this.httpClient.get(this.url + '/post');
  }

  // ***************************************** PEDIDOS ******************************************************

  getnum() {
    return this.httpClient.put(this.url + '/orders', null)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  saveOrder(order: any) {
    return this.httpClient.post(this.url + '/orders', order)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  saveResponse(order: any) {
    return this.httpClient.post(this.url + '/orders/response', order)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  getTransaction(idOrder: string) {

    let url = 'https://sandbox.wompi.co/v1/';

    if (!this.esPruebas) {
      url = 'https://production.wompi.co/v1/';
    }

    return this.httpClient.get(url + 'transactions/' + idOrder)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

}
