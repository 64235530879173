import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year:string = new Date().getFullYear().toString();
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToPrivacy() {
    this.router.navigate(['privacy']);
  }

  goToHome() {
    this.router.navigate(['/']);
  }
  
  showSection(tipo: string) {
    this.router.navigate(['/']);
    const element = document.querySelector(tipo);
    element?.scrollIntoView({ behavior: 'smooth' })
  }

}
