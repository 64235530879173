import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { CartService } from '../../shared/services/cart.service';
import { ServicesService } from '../../shared/services/services.service';

@Component({
  standalone: true,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [RouterOutlet, RouterLink, NgFor, NgIf, CurrencyPipe]
})
export class NavbarComponent implements OnInit {

  public products: any[] = [];
  public valor = 0;
  public total = 0;

  isSidebarOpen = false;
  showElement = false;

  menu_icon_variable: boolean = false;
  menuVariable: boolean = false;

  constructor(
    private cartService: CartService,
    private service: ServicesService,
    private router: Router
  ) { }

  openMenu() {
    this.menuVariable = !this.menuVariable;
    this.menu_icon_variable = !this.menu_icon_variable;
  }

  ngOnInit(): void {
    this.total = this.cartService.getTotalItems();
  }

  removeItem(product: any) {
    this.cartService.removeFromCart(product);

    this.service.publishSomeData({
      ev: 'add'
    });

    this.showElement = false;
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  toggleShowElement() {
    this.showElement = !this.showElement;
  }
  toggleShowElementO() {
    this.total = this.cartService.getTotalItems();
    this.showElement = true;
  }

  toggleShowElementC() {
    this.showElement = false;
  }

  pagar() {
    this.showElement = false;
    this.router.navigate(['/cart'])
  }

  showSection(tipo: string) {
    this.showElement = false;
    this.openMenu();
    this.router.navigate(['/']);
    const element = document.querySelector(tipo);
    element?.scrollIntoView({ behavior: 'smooth' })

    // this.router.navigate(['/home' + tipo, {behavior: 'smooth' }])

  }

  goToHome() {
    this.router.navigate(['/']);
  }

}
