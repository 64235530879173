import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ContactComponent } from '../../../components/contact/contact.component';
import { CartService } from '../../services/cart.service'
import { ServicesService } from '../../services/services.service';
import { NgFor, NgIf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare let $: any;

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [RouterOutlet, ContactComponent, NgFor, NgIf]
})
export class HomeComponent implements OnInit {
  title = 'caffetoapp';
  productos: any = [
    {
      id: '101',
      tipo: 'Torta Normal',
      imagen: './../../assets/images/lapastelidogAZ1.jpg',
      valor: 42000,
      nombre: 'TORTA CARNE, AVENA y ZANAHORIA'
    },
    {
      id: '102',
      tipo: 'Torta Normal',
      imagen: './../../assets/images/lapastelidogYC1.jpg',
      valor: 46000,
      nombre: 'TORTA CARNE, AVENA y YOGURT GRIEGO'
    },
  ];

  // windowScrolled = false;

  cargado = false;
  cargado1= false;
  blogs: any = [];
  blogs1: any = [];

  constructor(
    private router: Router,
    private cartService: CartService,
    private service: ServicesService,
    private toastr: ToastrService
  ) {

  }


  async ngOnInit() {
    // window.addEventListener('scroll', () => {
    //   this.windowScrolled = window.scrollY !== 0;
    // });

    await this.service.getAllPost().toPromise().then((res: any) => {
      this.blogs = res;
      // this.blogs1 = res.filter(resx => resx > res.lenght - 3);
      this.cargado = true;

    });

    await this.service.get3LastPost().toPromise().then((res: any) => {
      this.blogs1 = res;
      this.cargado1 = true;
    });

  }

  ngAfterViewInit() {

    // $('#example_1').whatsappChatSupport();

  }


  goToCart(tipo: string) {

    //* adicionar al carrito
    // this.router.navigate(['/cart'])

    const item = this.productos.filter((x: any) => x.id === tipo);

    this.cartService.addToCart(item).then((val: any) => {
      // this.actualizarCarro();
      if (val !== 'error') {

        this.service.publishSomeData({
          ev: 'add'
        });

        this.presentToast();
      }
    });
  }

  async presentToast() {
    this.toastr.info('Agregado!','Artículo adicionado al carrito.',{ positionClass: 'toast-bottom-right'});
    // this.toastr.success('Success message!', 'Congratulations');
  }

  showSection(tipo: string) {
    this.router.navigate(['/']);
    const element = document.querySelector(tipo);
    element?.scrollIntoView({ behavior: 'smooth' })

    // this.router.navigate(['/home' + tipo, {behavior: 'smooth' }])

  }
}
