import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ServicesService } from '../../services/services.service';
import { ToastrService } from 'ngx-toastr';
declare var WidgetCheckout: any;
import { Datos } from '../../../data/datos';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  imports: [RouterOutlet, RouterLink, NgFor, NgIf, ReactiveFormsModule, CurrencyPipe]
})
export class CartComponent implements OnInit {

  @ViewChild('recapt') recapt: any;
  @ViewChild('ciudad') ciudad: any;


  title = 'caffetoapp';
  submitted = true;
  recargo = 0;
  n1 = 0
  flete = 0;
  total = 0;
  totalCalculado = 0;
  totalCalculado1 = 0;
  products: any[] = []
  subTotal = 0;


  showCart = false;
  compras = [];

  minDate: any;
  fechaControl: any;
  disabledDates: any = new Date();

  isLoading = false;

  ciudades: any[] = [];

  todoForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    mascota: new FormControl('', Validators.required),
    cumple: new FormControl('', Validators.required),
    ciudad: new FormControl('', Validators.required),
    direccion: new FormControl<any>('', [Validators.required, Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    celular: new FormControl('', [Validators.required, Validators.maxLength(13)]),
    fecha: new FormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private cartService: CartService,
    private service: ServicesService,
    private toastr: ToastrService,
    private datos: Datos
  ) {
    const res = this.cartService.getCartItems();
    this.products = res;

    const dias = new Date().getHours() > 17 ? 2 : 1;

    var date = new Date();

    if (date < new Date('2025-01-23T00:00:00')) {
      date = new Date('2025-01-23T00:00:00');
    }

    date.setDate(date.getDate() + dias);
    // si es Domingo
    if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
    }

    const pad = '00';
    const ano = date.getFullYear().toString();
    const mes = date.getMonth() + 1;
    const dia = date.getDate();

    this.minDate = ano + '-' + pad.substring(0, pad.length - String(mes).length) + mes + '-' + pad.substring(0, pad.length - String(dia).length) + dia;

  }

  async ngOnInit() {

    this.datos.initilizeCiudades();
    this.ciudades = this.datos.ciudades;

    this.disabledDates = [new Date(2024, 12, 30), new Date(2024, 12, 31), new Date(2025, 1, 1), new Date(2025, 1, 2), new Date(2021, 4, 3)];

    this.getCart();

    const date = new Date();
    date.setDate(date.getDate() + 1);

    console.log(date);

    console.log(this.todoForm.controls.fecha.value);

    const pad = '00';
    const mes = date.getMonth() + 1;
    let dia = date.getDate();

    this.fechaControl = date.getFullYear().toString() + '-' + pad.substring(0, pad.length - String(mes).length) + mes + '-' + pad.substring(0, pad.length - String(dia).length) + dia;

    this.setFecha(date);
  }

  async getCart() {

    this.n1 = 0;
    this.total = 0;

    for await (let product of this.products) {
      this.n1 += product.count;
      this.total += product.count * product.valor
    }

    this.totalCalculado = this.total + this.flete; // + this.recargo;
    this.totalCalculado1 = this.totalCalculado * 100;

    this.calcularTotal();

    // await this.cartService.getCartItems().then(async (val: any) => {
    //   if (val.length > 0) {
    //     this.compras = val;
    //     this.showCart = true;

    //     for (let index = 0; index < this.compras.length; index++) {
    //       const element = this.compras[index];
    //       this.n1 += element.count;
    //       this.total += (Number(element.valor) * Number(element.count));
    //     }

    //     await this.calcularTotal();
    //   } else {
    //     this.showCart = false;
    //   }
    // });
  }

  goToCart(tipo: string) {
    //* adicionar al carrito
    this.router.navigate(['/cart'])
  }

  setCiudad(ciudad: any) {

    const ciu = ciudad.target.value;

    const index = this.ciudades.findIndex((x: any) => x.id === ciu);
    if (index != -1) {
      this.flete = Number(this.ciudades[index].flete);
    } else {
      this.flete = 0;
    }
    this.calcularTotal();
  }

  calcularTotal() {

    this.subTotal = Number(this.total);

    if (this.ciudad !== undefined && this.ciudad.value !== undefined) {
      const index = this.ciudades.findIndex((x: any) => x.id === this.ciudad.value);
      if (index != -1) {
        this.flete = Number(this.ciudades[index].flete);
      } else {
        this.flete = 0;
      }
    }

    this.totalCalculado = this.flete + this.recargo + this.subTotal;
    this.totalCalculado1 = this.totalCalculado * 100;
  }

  sumar(item: any) {

    // item.count = 1;

    this.cartService.addToCart(item).then((val: any) => {
      if (val !== 'error') {
        this.service.publishSomeData({
          ev: 'add'
        });
        this.getCart();
      }
    });


  }

  restar(item: any) {

    if (item.count === 1) {
      this.removerItem(item);
    } else {

      item.count = 1;

      this.cartService.substracFromCart(item).then((val: any) => {
        if (val !== 'error') {
          this.service.publishSomeData({
            ev: 'add'
          });
          this.getCart();
        }
      });
    }
  }

  async removerItem(item: any) {
    this.presentAlertConfirm(item);
    this.getCart();
  }

  async presentAlertConfirm(item: any) {

    const id = item.id;

    this.cartService.removeFromCart(id).then((val: any) => {
      if (val !== 'error') {
        this.getCart();

        this.service.publishSomeData({
          ev: 'remove'
        });

        this.toastr.error('', 'Artículo eliminado!', { positionClass: 'toast-bottom-right' });
      }
    });

  }

  async whatsapp() {

    Swal.fire({
      title: 'Enviar pedido',
      text: '¿Deseas enviar el pedido a whatsapp ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {

        // quitar 2000 de recargo
        this.recargo = 0

        this.totalCalculado = this.total + this.flete + this.recargo;
        this.totalCalculado1 = this.totalCalculado * 100;

        const misDatos = 'Mis%20datos%20son%3A%20%0D%0A%0D%0A' +
          '%20Nombre%3A%20' + this.todoForm.controls['nombre'].value + '%0D%0A' +
          '%20Mascota%3A%20' + this.todoForm.controls['mascota'].value + '%0D%0A' +
          '%20Cumple%3A%20' + this.todoForm.controls['cumple'].value + '%0D%0A' +
          '%20Fecha%3A%20' + this.todoForm.controls['fecha'].value + '%0D%0A' +
          '%20Celular%3A%20' + this.todoForm.controls['celular'].value + '%0D%0A' +
          '%20Email%3A%20' + this.todoForm.controls['email'].value + '%0D%0A' +
          '%20Ciudad%3A%20' + this.todoForm.controls['ciudad'].value + '%0D%0A' +
          '%20Direccion%3A%20' + encodeURIComponent(this.todoForm.controls['direccion'].value) + '%0D%0A%0D%0A';

        const wh = "573208323608";

        const url = 'https://api.whatsapp.com/send?phone=' + wh + '&text=';
        const saludo = '¡Hola!%0D%0A%0D%0AEstoy%20comprando%20desde%20lapastelidog.com%20y%20estos%20son%20los%20productos%20que%20quiero%20solicitar%3A%20%20%0D%0A%0D%0A';

        let itemsW = '';

        this.products.forEach(element => {
          itemsW += element.count + '%20x%20->%20%20' + element.tipo + ' ' + element.nombre +
            '%20por%3A%20$' + Number(element.valor).toFixed(0) + '%20%0D%0A%0D%0A';
        });

        // envio
        let envio = '';

        const totalFlete: number = Number(this.flete); //Number(this.recargo) +

        envio = '%20Envío: $' + totalFlete.toString() + ' ';
        envio += '%0D%0A%0D%0A';

        // Simbolo pesos = %24%C2%A0

        const final = '%20El%20total%20de%20mi%20compra%20es%20de%3A%20$' + Number(this.totalCalculado).toFixed(0) + '%0D%0A%0D%0AConfírmame%20si%20puedes%20realizar%20mi%20pedido.%0D%0A%0D%0A¡Muchas%20gracias!';

        const todos = url + saludo + itemsW + misDatos + envio + final;

        this.cartService.showAlert('LaPastelidog', 'El pedido ha sido enviado !')

        window.open(todos, '_blank');

        this.cartService.removeAllCartItems();
        this.service.deleteVariable('pedido');

        this.service.publishSomeData({
          ev: 'add'
        });

        this.submitted = false;
        this.limpiarDatos();

        this.router.navigate(['/home'], { replaceUrl: true });

      }
    });

  }

  async pagarW() {

    this.submitted = true;

    this.isLoading = true;

    if (this.todoForm.invalid) {
      this.cartService.showAlert('Error', '! Datos no validos, intente de nuevo !');
      return;
    }


    // quitar 2000 de recargo
    // this.recargo = 2000;

    this.totalCalculado = this.total + this.flete; // + this.recargo;
    this.totalCalculado1 = this.totalCalculado * 100;

    // 1. consultar numero pedido
    let numero = 1;
    await this.service.getnum().then(res => {
      numero = res.msg;
    });

    // 2. guardar pedido en firebase

    const pedido = {
      id: numero,
      nombre: this.todoForm.value.nombre,
      mascota: this.todoForm.value.mascota,
      cumple: this.todoForm.value.cumple,
      fechaEntrega: this.todoForm.value.fecha,
      ciudad: this.todoForm.value.ciudad,
      direccion: this.todoForm.value.direccion,
      email: this.todoForm.value.email,
      celular: this.todoForm.value.celular,
      recargo: this.recargo,
      flete: this.flete,
      Valor: this.totalCalculado,
      items: this.products,
      numero: numero.toString(),
      totalCalculado1: this.totalCalculado1,
      fechaCreacion: new Date().toISOString()
    }

    this.service.setVariable('pedido', pedido);

    //! NUEVO INTEGRITY WOMPI 14 SEP 2023
    // Referencia de la transacción: numero.toString()
    // Monto de la transacción en centavos: this.totalCalculado1
    // Moneda de la transacción: COP
    // Secreto de integridad: prod_integrity_LwPom2gMR4tAP6gN7GpJbXz
    //! FIN NUEVO INTEGRITY WOMPI 14 SEP 2023


    let integridad = '';

    await this.service.saveOrder(pedido).then(res => {
      console.log('pedido', res);
      integridad = res.integridad;
    });

    //! PRUEBAS
    let redirectURL = 'http://localhost:4200/pago';
    let publicKey = 'pub_test_d1uXW8L0LW8HLzzlvH1f5IhlgFQysy3W';

    if (!this.service.esPruebas) {
      //! PRODUCCION
      redirectURL = 'http://lapastelidog.com/pago';
      publicKey = 'pub_prod_EPxb7yg0hDZTXRnk6fI7To3fdigIeWxC';
    }

    var checkout = new WidgetCheckout({
      currency: 'COP',
      amountInCents: this.totalCalculado1,
      reference: numero.toString(),
      publicKey: publicKey,
      signature: {
        integrity: integridad
      },
      redirectUrl: redirectURL // Opcional
    })

    var _this = this;

    checkout.open(function (result: any) {
      var transaction = result.transaction

      _this.isLoading = false;

      setTimeout(function () {
        if (transaction.status === 'APPROVED') {
          _this.cartService.removeAllCartItems();
          _this.service.deleteVariable('pedido');

          _this.service.publishSomeData({
            ev: 'add'
          });

          _this.submitted = false;
          _this.limpiarDatos();
        }
        _this.router.navigateByUrl('/pago?id=' + transaction.id, { replaceUrl: true });

        // _this.todoForm.patchValue({
        //     recap2: ''
        // });

        _this.cartService.removeAllCartItems();

      }, 100);

    });
    this.isLoading = false;
  }

  limpiarDatos() {


    this.submitted = false;

    this.todoForm.controls["nombre"].setValue("");
    this.todoForm.controls["mascota"].setValue("");
    this.todoForm.controls["cumple"].setValue("");
    this.todoForm.controls["fecha"].setValue("");
    this.todoForm.controls["ciudad"].setValue("");
    this.todoForm.controls["direccion"].setValue("");
    this.todoForm.controls["email"].setValue("");
    this.todoForm.controls["celular"].setValue("");

    this.cartService.removeAllCartItems();
  }

  get f() {
    return this.todoForm.controls;
  }

  submit() {
    console.log(this.todoForm.value);
  }

  toggleLoading = () => {
    this.isLoading = true;

    //Faking an API call
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  };

  onChangeDate(fechaAnt: any) {

    let fecha: any = new Date();

    fecha = fechaAnt.target.value;

    this.setFecha(fecha);

  }

  setFecha(fecha: any) {

    // ? DEFINICIONES

    // *  1. define la fecha escogida en string y la la FEC "fec"

    const f1 = new Date(fecha).toISOString().split('T')[0];

    console.log(f1);

    let fec: Date = new Date(f1 + 'T00:00:00');

    // * 2. Define una fecha específica de redirección por si se determinó manualmente "fechaRedireccion"

    const fechaRedireccion = '2025-03-29';

    // * 3. Define festivos o rango de fechas de vacaciones

    const navidad1 = new Date('2025-03-23T00:00:00');
    const navidad2 = new Date('2025-03-28T00:00:00');
    const finano = new Date('2023-08-18T00:00:00');
    const vac1 = new Date('2023-09-16T00:00:00');
    const vac2 = new Date('2023-08-21T00:00:00');
    const vac3 = new Date('2023-08-22T00:00:00');
    const vac4 = new Date('2023-08-23T00:00:00');
    const vac5 = new Date('2023-08-24T00:00:00');

    // ? CALCULOS

    // * 4. Calcula que la fecha sea mayor a la fecha actual, sino pone la fecha actual

    if (fec < new Date()) {

      fec = new Date(this.fechaControl);

      this.toastr.warning('', 'No se puede escoger una fecha anterior.', { positionClass: 'toast-bottom-right' });

    }

    // * 5. si la fecha propuesta es menor o es vacacioines que la fecha de redireccion pone la fecha de redireccion

    if (fec >= navidad1 && fec <= navidad2) {

      fec = new Date(fechaRedireccion + 'T00:00:00');

      this.toastr.warning('', 'Son nuestras vacaciones!, elegimos otra fecha por ti.', { positionClass: 'toast-bottom-right' });

    }

    this.todoForm.controls["fecha"].setValue(fec.toISOString().split('T')[0]);

    this.toastr.success('', 'Nueva fecha seleccionada: ' + fec.toISOString().split('T')[0], { positionClass: 'toast-bottom-right' });

    // * 6. Domingos DESACTIVADO

    // if (fec.getDay() === 0) {

    //   Swal.fire({
    //     title: 'Atención',
    //     text: 'Normalmente no hacemos despachos los domingos (disponiblidad de los mensajeros), pero escríbenos y definimos una fecha !',
    //     icon: 'error'
    //   });

    //   this.todoForm.controls["fecha"].setValue(this.fechaControl);

    // }

  }
}
