<!-- <header class="header" data-header>
    <div class="container">

        <button class="nav-toggle-btn" aria-label="toggle manu" data-nav-toggler>
            <ion-icon name="menu-outline" aria-hidden="true" class="menu-icon"></ion-icon>
            <ion-icon name="close-outline" aria-label="true" class="close-icon"></ion-icon>
        </button>

        <a href="#" class="logo">Kitter</a>
        <div class="logo">
            <a (click)="goToHome()">
                <img class="imageHeader" src="/assets/img/logos/1.png">
            </a>
        </div>

        <nav class="navbar" data-navbar>
            <ul class="navbar-list">

                <li class="navbar-item">
                    <a href="#home" class="navbar-link" data-nav-link>Home</a>
                </li>

                <li class="navbar-item">
                    <a href="#shop" class="navbar-link" data-nav-link>Shop</a>
                </li>

                <li class="navbar-item">
                    <a href="#" class="navbar-link" data-nav-link>Collections</a>
                </li>

                <li class="navbar-item">
                    <a href="#" class="navbar-link" data-nav-link>Blogs</a>
                </li>

                <li class="navbar-item">
                    <a href="#" class="navbar-link" data-nav-link>Contact</a>
                </li>

            </ul>

            <a href="#" class="navbar-action-btn">Log In</a>
        </nav>

        <div class="header-actions">

            <button class="action-btn" aria-label="Search">
                <ion-icon name="search-outline" aria-hidden="true"></ion-icon>
            </button>

            <button class="action-btn user" aria-label="User">
                <ion-icon name="person-outline" aria-hidden="true"></ion-icon>
            </button>

            <button class="action-btn" aria-label="cart">
                <ion-icon name="bag-handle-outline" aria-hidden="true"></ion-icon>

                <span class="btn-badge">0</span>
            </button>

        </div>

    </div>
</header> -->


<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="logo">
      <a (click)="goToHome()">
        <img class="imageHeader" src="./assets/images/logoH.png">
        <!-- <img class="imageHeader" src="/assets/images/logoLPT.png"> -->
      </a>
    </div>

    <header>
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-12 text-end">

            <div class="menu_icon" [class.menu_iconClass]="menu_icon_variable" (click)="openMenu()">
              <span></span><span></span><span></span>
            </div>
            <div class="menu" id="navbarSupportedContent" [class.menuClasse]="menuVariable">
              <ul class="navbar-nav">
                <li class="nav-item"><a (click)="showSection('#home')" class="nav-link">Inicio</a></li>
                <li class="nav-item"><a (click)="showSection('#products')" class="nav-link">Productos</a></li>
                <li class="nav-item"><a (click)="showSection('#about')" class="nav-link"> Quiénes Somos</a></li>
                <li class="nav-item"><a (click)="showSection('#nutritips')" class="nav-link">Nutri Tips</a></li>
                <li class="nav-item"><a (click)="showSection('#faqs')" class="nav-link"> FAQs</a></li>
              </ul>
            </div>


            <div class="carro">
              <div class="menuCarro" (click)="toggleShowElement()">
                <a class="iconCarro">
                  <!-- <img src="./assets/img/cart.png" class="img-fluid" alt=""> -->
                  <i class="ti-shopping-cart"></i>
                </a>
                <span class="cart_qty_cls">{{ valor }}</span>
              </div>
            </div>
            <div>
              <ul class="shopping-cart" *ngIf='!products.length && showElement'>
                <div class="close-circle text-right pb-2">
                  <a href="javascript:void(0)" (click)="toggleShowElementC()">X
                    <i class="fa fa-close" aria-hidden="true"></i>
                  </a>
                </div>
                <h5 (click)="toggleShowElementC()" class="p-2 fs-24 text-center">Su carrito de compras está vacío.</h5>
              </ul>

              <ul class="shopping-cart" style="list-style: none;" *ngIf='products.length > 0 && showElement'>
                <div class="close-circle text-left pb-2">
                  <a class="fc-secondary fs-18" href="javascript:void(0)" (click)="toggleShowElementC()"> Cerrar
                    <i class="fa fa-close" aria-hidden="true"></i>
                  </a>
                </div>
                <li *ngFor="let product of products">
                  <div class="media">
                    <a href="javascript:void(0)" [routerLink]="['/home#home']">
                      <img class="p-2" [src]="product?.imagen" alt="torta" width="100">
                    </a>
                    <div class="media-body">
                      <a class="fs-16 fc-black" href="javascript:void(0)" [routerLink]="['/cart']">
                        <p>{{ product?.nombre }}</p>
                      </a>
                      <strong>
                        <span class="m-2 fs-16 fc-black --fw-700">
                          {{ product?.count }} x
                          {{ product?.valor | currency
                          :'USD':'symbol-narrow':'2.0-0' }}
                        </span>
                      </strong>
                    </div>
                    <div class="close-circle">
                      <a href="javascript:void(0)" (click)="removeItem(product)"><i class="fa fa-trash fc-danger p-3"
                          aria-hidden="true"></i></a>
                    </div>
                  </div>

                </li>
                <hr>
                <li>
                    <span class="fs-14 --fw-700 p-1 text-left fc-danger">El valor del flete se calcula en el carrito.</span>
                </li>
                <hr>
                <li>
                  <div class="total">
                    <span class="fs-18 --fw-700 p-3">Subtotal: {{ total | currency
                      :'USD':'symbol-narrow':'2.0-0'}}
                    </span>
                  </div>
                </li>
                <li>
                  <div class="buttons">
                    <a class="btn btn-dark w-100 checkout" (click)="pagar()">IR A
                      PAGAR</a>
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </header>

  </div>
</nav>
