import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Datos {

    public tortas: any;
    public galletas: any;
    public ciudades: any;

    initilizeTortas() {
        this.tortas = [
            {
                id: 101,
                tipo: 'torta',
                imagen: './../../assets/lapastelidogAZ1.jpg',
                web: './../../assets/lapastelidogAZ1.webp',
                valor: 42000,
                nombre: 'TORTA CARNE, AVENA Y ZANAHORIA',
                textoCompleto: 'La torta está compuesta por una base de avena, carne de res o pollo y zanahoria, en su alrededor tiene 11 galletas de banano y por último un topping de zanahoria fresca. <br><br> El diámetro del pastel es de 13cm y las porciones dependen del tamaño del perro (3 para grande, 6 para mediano y 8 para pequeño). Incluye el nombre del perrito y los años que cumple en galleta, adicional de una velita, cinta y galleta decorativa. '
            },
            {
                id: 102,
                tipo: 'torta',
                imagen: './../../assets/lapastelidogYC1.jpg',
                web: './../../assets/lapastelidogYC1.webp',
                valor: 46000,
                nombre: 'TORTA CARNE, AVENA Y YOGURT GRIEGO',
                textoCompleto: 'La torta está compuesta por una base de avena, carne de res o pollo y yogurt griego, en su alrededor tiene 11 galletas de banano y por último un topping de yogurt griego.<br><br>  El diámetro del pastel es de 13cm y las porciones dependen del tamaño del perro (3 para grande, 6 para mediano y 8 para pequeño). Incluye el nombre del perrito y los años que cumple en galleta, adicional de una velita, cinta y galleta decorativa.'
            },
            // {
            //     id: 103,
            //     tipo: 'torta',
            //     imagen: './../../assets/lapastelidogPA1.jpg',
            //     web: './../../assets/lapastelidogPA1.webp',
            //     valor: 34000,
            //     nombre: 'CARNE, AVENA Y PAPA',
            //     textoCompleto: 'La torta está compuesta por una base de avena, carne de res o pollo y papa, en su alrededor tiene 11 galletas de banano y por último un topping de puré de papa.<br><br>  El diámetro del pastel es de 13cm y las porciones dependen del tamaño del perro (3 para grande, 6 para mediano y 8 para pequeño). Incluye el nombre del perrito y los años que cumple en galleta, adicional de una velita, cinta y galleta decorativa.',
            //     nuevo: true
            // },
        ]
    }

    initilizeGalletas() {
        this.galletas = [
            {
                id: 201,
                tipo: 'galleta',
                imagen: './../../assets/lapastelidogBA.jpg',
                web: './../../assets/lapastelidogBA.webp',
                valor: 6000,
                nombre: 'BANANO Y AVENA',
                textoCompleto: '30 galletas en forma de huesito que ayudan al sistema digestivo y a premiar a tu mascota.<br><br>Horneadas con mucho amor. '
            },
            {
                id: 202,
                tipo: 'galleta',
                imagen: './../../assets/lapastelidogHA.jpg',
                web: './../../assets/lapastelidogHA.webp',
                valor: 8000,
                nombre: 'HIGADO Y AVENA',
                textoCompleto: '18 galletas redondas con chips de hígado. A tu perrito le encantarán..<br><br>Horneadas con mucho amor.'
            },
            {
                id: 203,
                tipo: 'galleta',
                imagen: './../../assets/lapastelidogZA.jpg',
                web: './../../assets/lapastelidogZA.webp',
                valor: 9000,
                nombre: 'CHIA, ZANAHORIA Y OLIVA',
                textoCompleto: '30 galletas en forma de huesito que ayudan al sistema circulatorio de tu perrito y también a disminuir la caída del pelo.<br><br>Horneadas con mucho amor. '
            },
        ]
    }

    initilizeCiudades() {
        this.ciudades = [
            {
                id: 'Medellin',
                name: 'MEDELLIN',
                flete: 6000
            },
            {
                id: 'Bello',
                name: 'BELLO',
                flete: 8000
            },
            {
                id: 'Caldas',
                name: 'CALDAS',
                flete: 13000
            },
            {
                id: 'Copacabana',
                name: 'COPACABANA',
                flete: 13000
            },
            {
                id: 'Envigado',
                name: 'ENVIGADO',
                flete: 8000
            },
            {
                id: 'Itagui',
                name: 'ITAGUI',
                flete: 8000
            },
            {
                id: 'La Estrella',
                name: 'LA ESTRELLA',
                flete: 10000
            },
            {
                id: 'Sabaneta',
                name: 'SABANETA',
                flete: 8000
            },
            {
                id: 'sanAntonio',
                name: 'SAN ANTONIO DE PRADO (Corregimiento) ',
                flete: 10000

            },
            {
                id: 'sanCristobal',
                name: 'SAN CRISTOBAL (Urbano) ',
                flete: 10000
            },
            // {
            //     id: 'santaElena',
            //     name: 'SANTA ELENA (Corregimiento) ',
            //     flete: 12000
            // }
        ]
    }
}
