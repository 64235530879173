import { Routes } from '@angular/router';
import { CartComponent } from './shared/components/cart/cart.component';
import { HomeComponent } from './shared/components/home/home.component';
import { PagoPage } from './shared/components/pago/pago.page';

export const routes: Routes = [

    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'cart', component: CartComponent },
    { path: 'pago', component: PagoPage },
    { path: '**', component: HomeComponent }  // Wildcard route for a 404 page
];

