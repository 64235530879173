import { Component, OnInit, ViewChild } from '@angular/core';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterOutlet, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocationStrategy, APP_BASE_HREF, PathLocationStrategy } from '@angular/common';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { CartService } from './shared/services/cart.service';
import { ServicesService } from './shared/services/services.service';
// import * as $ from "jquery";
import AOS from "aos";
declare let $: any;

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, NavbarComponent, FooterComponent, PreloaderComponent],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: APP_BASE_HREF, useValue: '/' }
  ]
})
export class AppComponent implements OnInit {
  @ViewChild('headerValue', { static: true }) valor!: NavbarComponent;
  @ViewChild('headerValue', { static: true }) products!: NavbarComponent;

  title = 'caffetoapp';
  location: any;
  routerSubscription: any;

  constructor(
    private router: Router,
    private cartService: CartService,
    private service: ServicesService,
    private breakpointObserver: BreakpointObserver
  ) {

    this.recallJsFuntions();

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
          console.log('Pantalla móvil detectada');
      }
  });

  }

  ngOnInit() {

    AOS.init();

    this.recallJsFuntions();


    // const toastr = this.injector.get(ToastrService);
    // const toastConfig: ToastNoAnimation = {
    //   options:{
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     timeOut: 3000,
    //     disableTimeOut: false,
    //     closeButton: false,
    //     extendedTimeOut: 0,
    //     progressAnimation: 'decreasing',
    //     enableHtml: false,
    //     toastClass: '',
    //     titleClass: '',
    //     messageClass: '',
    //     easing: 'ease-in',
    //     easeTime: 300,
    //     tapToDismiss: true,
    //     // toastComponent?: ComponentType<any>;
    //     onActivateTick: false,
    //     newestOnTop: true,
    //     payload: null
    //   }
    // };
    // toastr.setDefaults(toastConfig);

    this.getCart();

    this.service.getObservable().subscribe((data) => {
      console.log('Data received', data);
      this.getCart();
    });
  }

  ngAfterViewInit() {
    // $('#example_1').whatsappChatSupport();
  }

  recallJsFuntions() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          $('.preloader').fadeIn('slow');
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/main.js');
        $('.preloader').fadeOut('slow');
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  async getCart() {

    this.valor.valor = this.cartService.getCountItems();
    this.valor.products = this.cartService.getCartItems();

  }

  goToCart(tipo: string) {
    //* adicionar al carrito
    this.router.navigate(['/cart'])
  }
}
