
<app-preloader></app-preloader>
<app-navbar #headerValue></app-navbar>
    <router-outlet></router-outlet>
<app-footer></app-footer>
<!--

<div class="whatsapp_chat_support wcs_fixed_right" style="bottom: 60px;" id="example_1">
  <div class="wcs_button_label">
    Dudas? pregunta en Whatsapp!
  </div>
  <div class="wcs_button wcs_button_circle" style="padding: 8px;">
      <a class="fab fa-whatsapp fa-2x" ></a>
  </div>

  <div class="wcs_popup">
    <div class="wcs_popup_close">
      <span class="fa fa-close"></span>
    </div>
    <div class="wcs_popup_header">
      <strong>Para tus pedidos o dudas puedes contactar con nosotros:</strong>
      <br>
      <div class="wcs_popup_header_description">Click en cualquiera de nuestros representantes</div>
    </div>
    <div class="wcs_popup_person_container">
      <div class="wcs_popup_person" data-number="573208323608"
        data-availability='{ "monday":"08:30-18:30", "tuesday":"08:30-20:30", "wednesday":"08:30-20:30", "thursday":"08:30-20:30", "friday":"08:30-20:30", "saturday":"8:30-16:00" }'
        data-default-msg="Hola LaPastelidog, quiero hacer un pedido desde el chat de la web !">
        <div class="wcs_popup_person_img"><img src="assets/img/Pastelidog.jpeg" alt=""></div>
        <div class="wcs_popup_person_content">
          <div class="wcs_popup_person_name">Asesor Principal</div>
          <div class="wcs_popup_person_description">Soporte de Ventas</div>
          <div class="wcs_popup_person_status">En línea</div>
        </div>
      </div>

      <div class="wcs_popup_person" data-number="573113687992"
        data-availability='{ "monday":"08:30-18:30", "tuesday":"08:30-18:30", "wednesday":"08:30-18:30", "thursday":"08:30-18:30", "friday":"08:30-18:30", "saturday":"8:30-16:00" }'
        data-default-msg="Hola Claudia, quiero hacer un pedido desde el chat de la web LaPASTELIDOG !">
        <div class="wcs_popup_person_img"><img src="assets/img/Claudia.jpeg" alt=""></div>
        <div class="wcs_popup_person_content">
          <div class="wcs_popup_person_name">Claudia Ruiz</div>
          <div class="wcs_popup_person_description">Soporte de Ventas</div>
          <div class="wcs_popup_person_status">En línea</div>
        </div>
      </div>

      <div class="wcs_popup_person" data-number="573192593857"
      data-availability='{ "monday":"08:30-18:30", "tuesday":"08:30-18:30", "wednesday":"08:30-18:30", "thursday":"08:30-18:30", "friday":"08:30-18:30", "saturday":"8:30-16:00" }'
      data-default-msg="Hola Daniela, quiero hacer un pedido desde el chat de la web LaPASTELIDOG !">
        <div class="wcs_popup_person_img"><img src="assets/img/Daniela.jpg" alt=""></div>
        <div class="wcs_popup_person_content">
          <div class="wcs_popup_person_name">Daniela García</div>
          <div class="wcs_popup_person_description">Soporte de Ventas</div>
          <div class="wcs_popup_person_status">En línea</div>
        </div>
      </div>

      <div class="wcs_popup_person" data-number="573012178813"
      data-availability='{ "monday":"08:30-18:30", "tuesday":"08:30-18:30", "wednesday":"08:30-18:30", "thursday":"08:30-18:30", "friday":"08:30-18:30", "saturday":"8:30-16:00" }'
      data-default-msg="Hola Jhonatan, quiero hacer un pedido desde el chat de la web LaPASTELIDOG !">
        <div class="wcs_popup_person_img"><img src="assets/img/Jhonatan.jpeg" alt=""></div>
        <div class="wcs_popup_person_content">
          <div class="wcs_popup_person_name">Jhonatan Ortiz</div>
          <div class="wcs_popup_person_description">Soporte de Ventas</div>
          <div class="wcs_popup_person_status">En línea</div>
        </div>
      </div>
    </div>
  </div>
</div>

 -->
