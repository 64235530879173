<main>
  <article>

    <!--
    - #HERO
  -->

    <!-- <div class="banner">
      <svg viewBox="0 0 130 25" preserveAspectRatio="none">
        <path d="M0,0 C30,20 70,20 100,0 L100,00 L0,0 Z" fill="blue" />
      </svg>
    </div> -->

    <!-- <div class="banner">
       <h1>¡Bienvenido a mi sitio web!</h1>
      <p>Este es un banner con un recorte en forma de onda.</p>
    </div> -->



    <section class="section hero has-bg-image" id="home" aria-label="home" style="background-image: url('./assets/images/banner6.jpg'); background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top;">
      <div class="container">

        <!-- <figure style="display: block; right: 100px; top: 80px; position: absolute;">
          <img src="./assets/images/dog2.png" width="500" height="360" loading="lazy" alt="mona">
        </figure> -->

        <!-- Carousel Start -->
        <div class="carousel-container"
          style="max-width: 40%; display: block; right: 100px; top: 150px; position: absolute;">
          <div class="carousel slide overlay-bottom" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <figure>
                  <img src="./assets/images/dog2.png" width="500" height="360" loading="lazy" alt="mostro">
                </figure>
              </div>
              <div class="carousel-item">
                <figure>
                  <img src="./assets/images/dog3.png" width="500" loading="lazy" alt="frenchi">
                </figure>
              </div>
              <div class="carousel-item">
                <figure>
                  <img src="./assets/images/torta3wbg.png" width="500" loading="lazy" alt="torta5">
                </figure>
              </div>
              <div class="carousel-item">
                <figure>
                  <img src="./assets/images/torta4wbg.png" height="500" loading="lazy" alt="torta5">
                </figure>
              </div>
              <div class="carousel-item">
                <figure>
                  <img src="./assets/images/torta5wbg.png" height="400" loading="lazy" alt="torta5">
                </figure>
              </div>
            </div>

          </div>
        </div>
        <!-- Carousel End -->
        <br><br><br>
        <h1 class="h1 hero-title pt-5">
          <span class="span">Tortas y Fiestas para</span> PELUDOS
        </h1>

        <p class="hero-subtitle">🎉 Creemos en celebrar cada día de la vida 🎉</p>
        <p class="hero-text">Siéntete en
          confianza de escribirnos a nuestro WhatsApp y hacer una experiencia única para tu peludito con nuestros
          pedidos personalizados. 🐾 </p>
        <p class="hero-text"> puedes hacer tus pedidos en nuestra web y enviarlos a nuestro
          WhatsApp. </p>
        <p class="hero-text">📱 Realizamos despachos en Medellín y toda el área metropolitana, y puedes hacer tus pagos
          utilizando WOMPI de Bancolombia. 💳</p>
        <br>
        <a (click)="showSection('#ventas')" class="btn">Compra Ya</a>

      </div>
    </section>





    <!--
    - #CATEGORY
  -->

    <section id="products" class="section category" aria-label="category">
      <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="2000">

        <h2 class="h2 section-title">
          <span class="span">Nuestros</span> Productos
        </h2>

        <ul class="contenedor-galeria">

          <li>
            <div data-aos="flip-left">

              <!-- <figure class="card-banner img-holder shadow" style="--width: 330; --height: 300;">
                <img src="./assets/images/Tortas.jpg" width="330" height="300" loading="lazy" alt="Cat Food"
                  class="img-cover">
              </figure> -->

              <!-- <label class="shadow"> -->
              <!-- <input type="checkbox"> -->
              <img src="./assets/images/Tortas.jpg" loading="lazy" alt="torta lapastelidog"
                class="imagen-galeria imagen-principal shadow">
              <!-- </label> -->

              <h3 class="h3">
                <a href="#" class="card-title">Tortas</a>
              </h3>

            </div>
          </li>

          <li>
            <div data-aos="flip-right">

              <!-- <figure class="card-banner img-holder shadow" style="--width: 330; --height: 300;">
                <img src="./assets/images/Maxitortas.jpg" width="330" height="300" loading="lazy" alt="Cat Toys"
                  class="img-cover">
              </figure> -->

              <!-- <label class="contenedor-imagen shadow"> -->
              <!-- <input type="checkbox"> -->
              <img src="./assets/images/Maxitortas.jpg" loading="lazy" alt="minitorta lapastelidog"
                class="imagen-galeria imagen-principal shadow">
              <!-- </label> -->

              <h3 class="h3">
                <a href="#" class="card-title">MaxiTortas</a>
              </h3>

            </div>
          </li>

          <li>
            <div data-aos="flip-left">

              <!-- <figure class="card-banner img-holder shadow" style="--width: 330; --height: 300;"> -->

              <!-- <label class="contenedor-imagen shadow"> -->
              <!-- <input type="checkbox"> -->
              <img src="./assets/images/MiniTorta.jpg" loading="lazy" alt="minitorta lapastelidog"
                class="imagen-galeria imagen-principal shadow">
              <!-- </label> -->


              <!-- </figure> -->

              <h3 class="h3">
                <a href="#" class="card-title">MiniTortas</a>
              </h3>

            </div>
          </li>

          <li>
            <div data-aos="flip-right">

              <!-- <figure class="card-banner img-holder shadow" style="--width: 330; --height: 300;">
                <img src="./assets/images/Cupcakes.jpg" width="330" height="300" loading="lazy" alt="Dog Toys"
                  class="imagen-galeria imagen-principal">
              </figure> -->

              <!-- <label class="shadow"> -->
              <!-- <input type="checkbox"> -->
              <img src="./assets/images/Cupcakes.jpg" loading="lazy" alt="torta lapastelidog"
                class="imagen-galeria imagen-principal shadow">
              <!-- </label> -->

              <h3 class="h3">
                <a href="#" class="card-title">Cupcakes</a>
              </h3>

            </div>
          </li>

          <li>
            <div data-aos="flip-left">

              <!-- <figure class="card-banner img-holder shadow" style="--width: 330; --height: 300;">
                <img src="./assets/images/ComboFiesta.jpg" width="330" height="300" loading="lazy"
                  alt="Dog Sumpplements" class="imagen-galeria imagen-principal">
              </figure> -->

              <!-- <label class="shadow"> -->
              <!-- <input type="checkbox"> -->
              <img src="./assets/images/ComboFiesta.jpg" loading="lazy" alt="torta lapastelidog"
                class="imagen-galeria imagen-principal shadow">
              <!-- </label> -->

              <h3 class="h3">
                <a href="#" class="card-title">Kit Fiesta</a>
              </h3>

            </div>
          </li>

        </ul>

      </div>
    </section>


    <!-- Service Start -->
    <div id="ventas" class="container-fluid pt-5 mb-5">
      <div class="container">
        <div class="section-title">
          <h4 class="text-primary text-uppercase" style="letter-spacing: 5px;">Productos</h4>
          <h1 class="display-4">Ventas online</h1>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-3 pb-5">
            <div class="row align-items-center">
              <div class="col-sm-4">
                <img class="img-fluid mb-3 mb-sm-3 shadow br-20" src="assets/images/lapastelidogAZ1.jpg" alt="">
              </div>
              <div class="col-sm-8 center" style="text-align: center;">
                <br>
                <h1>TORTA CARNE, AVENA y ZANAHORIA</h1>
                <h3 style="color: #9e2222">Tambien en Minitorta y Maxitorta</h3>
                <span class="d-inline-flex m-3 align-middle">

                  <table class="tableProducts">
                    <colgroup>
                      <col span="1" style="width: 50%;">
                    </colgroup>
                    <tr>
                      <th class="text-center fs-32 fc-primary">$ 42.000</th>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <a (click)="goToCart('101')" class="btn compra">AGREGAR AL CARRITO</a>
                      </td>
                    </tr>
                  </table>
                </span>
                <br><br>
                <p class="m-t-2 m-b-2 fs-20">La torta está compuesta por una base de avena, carne de res o pollo y
                  zanahoria,
                  en su alrededor tiene 11 galletas de banano y por último un topping de zanahoria fresca.</p>
                <br>
                <p class="m-b-2 fs-20">El diámetro del pastel es de 13cm y las porciones dependen del tamaño del perro
                  (3 para grande, 6 para mediano y 8 para pequeño). Incluye el nombre del perrito y los años que cumple
                  en galleta, adicional de una velita, cinta y galleta decorativa.</p>

              </div>
            </div>
          </div>

          <img class="imagenBarra text-center mb-5" src="assets/images/barras.png" alt="barra">

          <div class="col-lg-12 mb-3 mt-5 pb-5">
            <div class="row align-items-center">
              <div class="col-sm-4">
                <img class="img-fluid mb-3 mb-sm-3 shadow br-20" src="assets/images/lapastelidogYC1.jpg" alt="">
              </div>
              <div class="col-sm-8 center" style="text-align: center;">
                <br>
                <h1>TORTA CARNE, AVENA y YOGURT GRIEGO</h1>
                <h3 style="color: #9e2222">Tambien en Minitorta y Maxitorta</h3>
                <span class="d-inline-flex m-3 align-middle">

                  <table class="tableProducts" style="border-radius: 5px;">
                    <colgroup>
                      <col span="1" style="width: 50%;">
                    </colgroup>
                    <tr>
                      <th class="text-center fs-32 fc-primary">$ 46.000</th>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <a (click)="goToCart('102')" class="btn compra">AGREGAR AL CARRITO</a>
                      </td>
                    </tr>
                  </table>
                </span>
                <br><br>
                <p class="m-t-2 m-b-2 fs-20">La torta está compuesta por una base de avena, carne de res o pollo y
                  yogurt griego, en su alrededor tiene 11 galletas de banano y por último un topping de yogurt griego.
                </p>
                <br>
                <p class="m-b-2 fs-20">El diámetro del pastel es de 13cm y las porciones dependen del tamaño del perro
                  (3 para grande, 6 para mediano y 8 para pequeño). Incluye el nombre del perrito y los años que cumple
                  en galleta, adicional de una velita, cinta y galleta decorativa.</p>

              </div>
            </div>
          </div>

          <img class="imagenBarra text-center mb-5" src="assets/images/barras.png" alt="barra">

          <div class="logoRappi col-lg-12 mb-3 mt-5 pb-5 text-center">
            <h3 class="text-center fs-30">ó Compra por Rappi</h3>
            <br><br>
            <a href="https://www.rappi.com.co/tiendas/900102034-la-pastelidog-ecommerce-mt"
              class="footer-link text-center" style="max-height: 70px; cursor: pointer; display: ruby; "
              target="_blank"><img class="text-center" src="../../../assets/images/rappi.png" alt="rappi"
                style="max-height: 100px;"> </a>
          </div>

        </div>
      </div>
    </div>
    <!-- Service End -->

    <!-- About Start -->
    <div id="about" class="container-fluid pb-5" data-aos="flip-left">
      <div class="container" data-aos="zoom-in" data-aos-duration="2000">

        <h2 class="h2 section-title">
          <span class="span">Nuestro</span> compromiso
        </h2>

        <div class="row">
          <div class="col-lg-4 py-0 py-lg-5" data-aos="zoom-in-down" data-aos-duration="1500">
            <h1 class="mb-3 text-especial">Saludable</h1>
            <h5 class="mb-3 text-para">Somos una marca que busca la felicidad de los peduditos y sus perripapás, ademas
              de ofrecer un producto con 0% de colorantes y conservantes</h5>
            <p class="fs-18">En cada uno de nuestros productos se destaca la dedicación y el cuidado que ponemos para
              que tengas toda
              la felicidad en tus celebraciones.</p>
            <p class="fs-18">Por eso tenemos plena confianza en que tendrás una experiencia única y llena
              de alegría, sabor y salud.</p>
            <!-- <a href="" class="btn btn-secondary font-weight-bold py-2 px-4 mt-2">Learn More</a> -->
          </div>
          <div class="col-lg-4 py-5 py-lg-0" style="min-height: 500px;">
            <div class="position-relative h-100">
              <img class="position-absolute w-100 h-100" src="assets/images/dog4.png" style="object-fit: cover;">
            </div>
          </div>
          <div class="col-lg-4 py-0 py-lg-5" data-aos="fade-left">
            <h1 class="mb-3 text-especial">Artesanal</h1>
            <h5 class="mb-3 text-para">Horneamos nuestros productos el mismo dia de la entrega para garantizar su
              calidad.</h5>
            <p class="fs-18">En La Pastelidog nos caracterizamos por nuestra pasión por la calidad, el
              compromiso por el constante trabajo por la sostenibilidad ambiental y
              protección de los peluditos. Es por eso que nuestros productos se producen
              el mismo dia de las entregas, garantizando la calidad y conservación.</p>
            <!-- <h5 class="mb-3"><i class="fa fa-check text-primary mr-3"></i>Lorem ipsum dolor sit amet</h5>
              <h5 class="mb-3"><i class="fa fa-check text-primary mr-3"></i>Lorem ipsum dolor sit amet</h5>
              <h5 class="mb-3"><i class="fa fa-check text-primary mr-3"></i>Lorem ipsum dolor sit amet</h5>
              <a href="" class="btn btn-primary font-weight-bold py-2 px-4 mt-2">Learn More</a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->


    <!--
    - #PRODUCT
  -->

    <!-- <section class="section product" id="nutritips" aria-label="product">
      <div class="container" data-aos="zoom-in" data-aos-duration="2000">

        <h2 class="h2 section-title">
          <span class="span">Nutri</span> TIPS
        </h2>

        <ul class="grid-list">

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-1.jpg" width="360" height="360" loading="lazy"
                  alt="Commodo leo sed porta" class="img-cover default">
                <img src="./assets/images/product-1_0.jpg" width="360" height="360" loading="lazy"
                  alt="Commodo leo sed porta" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(1)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Commodo leo sed porta</a>
                </h3>

                <data class="card-price" value="15">$15.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-2.jpg" width="360" height="360" loading="lazy"
                  alt="Purus consequat congue sit" class="img-cover default">
                <img src="./assets/images/product-2_0.jpg" width="360" height="360" loading="lazy"
                  alt="Purus consequat congue sit" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Purus consequat congue sit</a>
                </h3>

                <data class="card-price" value="45">$45.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-3.jpg" width="360" height="360" loading="lazy"
                  alt="Morbi vel arcu scelerisque" class="img-cover default">
                <img src="./assets/images/product-3_0.jpg" width="360" height="360" loading="lazy"
                  alt="Morbi vel arcu scelerisque" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Morbi vel arcu scelerisque</a>
                </h3>

                <data class="card-price" value="45">$45.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-4.jpg" width="360" height="360" loading="lazy"
                  alt="Morbi vel arcu scelerisque" class="img-cover default">
                <img src="./assets/images/product-4_0.jpg" width="360" height="360" loading="lazy"
                  alt="Morbi vel arcu scelerisque" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Morbi vel arcu scelerisque</a>
                </h3>

                <data class="card-price" value="49">$49.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-5.jpg" width="360" height="360" loading="lazy"
                  alt="Morbi vel arcu scelerisque" class="img-cover default">
                <img src="./assets/images/product-5_0.jpg" width="360" height="360" loading="lazy"
                  alt="Morbi vel arcu scelerisque" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Morbi vel arcu scelerisque</a>
                </h3>

                <data class="card-price" value="85">$85.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-6.jpg" width="360" height="360" loading="lazy"
                  alt="Nam justo libero porta ege" class="img-cover default">
                <img src="./assets/images/product-6_0.jpg" width="360" height="360" loading="lazy"
                  alt="Nam justo libero porta ege" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Nam justo libero porta ege</a>
                </h3>

                <data class="card-price" value="85">$85.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-7.jpg" width="360" height="360" loading="lazy"
                  alt="Nam justo libero porta ege" class="img-cover default">
                <img src="./assets/images/product-7_0.jpg" width="360" height="360" loading="lazy"
                  alt="Nam justo libero porta ege" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Nam justo libero porta ege</a>
                </h3>

                <data class="card-price" value="85">$85.00</data>

              </div>

            </div>
          </li>

          <li>
            <div class="product-card">

              <div class="card-banner img-holder" style="--width: 360; --height: 360;">
                <img src="./assets/images/product-8.jpg" width="360" height="360" loading="lazy"
                  alt="Etiam commodo leo sed" class="img-cover default">
                <img src="./assets/images/product-8_0.jpg" width="360" height="360" loading="lazy"
                  alt="Etiam commodo leo sed" class="img-cover hover">

                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button>
              </div>

              <div class="card-content">

                <div class="wrapper">
                  <div class="rating-wrapper gray">
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                    <ion-icon name="star" aria-hidden="true"></ion-icon>
                  </div>

                  <span class="span">(0)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Etiam commodo leo sed</a>
                </h3>

                <data class="card-price" value="55">$55.00</data>

              </div>

            </div>
          </li>

        </ul>

      </div>
    </section> -->


    <section class="section product" id="nutritips" aria-label="product">
      <div class="container" data-aos="zoom-in" data-aos-duration="2000">

        <h2 class="h2 section-title">
          <span class="span">Nutri</span> TIPS
        </h2>

        <ul class="grid-list" *ngIf="cargado1">

          <li *ngFor="let item of blogs1">
            <div class="product-card">

              <div class="card-banner img-holder shadow" style="--width: 360; --height: 360;">
                <img src="{{item.imagen}}" width="360" height="360" loading="lazy" alt="nutritip lapastelidog"
                  class="img-cover default">
                <img src="{{item.imagen}}" width="360" height="360" loading="lazy" alt="nutritip lapastelidog"
                  class="img-cover hover">
                <!--
                <button class="card-action-btn" aria-label="add to card" title="Add To Card">
                  <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                </button> -->

              </div>

              <div class="card-content">

                <!-- <div class="wrapper">
                  <div class="rating-wrapper">
                  </div>

                  <span class="span">(1)</span>
                </div>

                <h3 class="h3">
                  <a href="#" class="card-title">Commodo leo sed porta</a>
                </h3>

                <data class="card-price" value="15">$15.00</data> -->

                <label color="light">
                  <h2 class="tituloBlog card-price">{{item.titulo}}</h2>
                  <h3 class="fechaBlog">{{item.fecha}}</h3>
                  <p class="textoBlog fs-18" [innerHTML]="item.descripcion"></p>
                  <!-- <p class="textoBlog">{{item.descripcion2}}</p> -->
                  <br>
                  <button class="btn" color="warning">
                    ver mas...</button>
                </label>


              </div>

            </div>
          </li>

        </ul>

        <img *ngIf="!cargado1" src="./../../assets/walkingdog.gif" style="height:100px" />
      </div>
    </section>

    <!-- <section class="chips" style="max-width:1300px; margin: auto;">
      <h1 class="TituloProductos">Todos los tips</h1>
      <p>Click en cada TIP para ver mas...</p>
      <label>
        <a style="color:red; font-size: 18pt;">Rojo = Prohibido</a>
        <br>
        <a style="color:green; font-size: 18pt;">Verde = Beneficioso</a>
      </label>
      <br>
      <br>
      <div class="contenedorChips" *ngIf="cargado">
        <button *ngFor="let item of blogs" >
          <p><a>{{item.titulo}}</a></p>
        </button>
      </div>
      <img *ngIf="!cargado" src="./../../assets/walkingdog.gif" style="height:100px" />
    </section> -->


    <!--
    - #SERVICE
  -->

    <section class="section service" aria-label="service">
      <div class="container" data-aos="flip-left">

        <img src="./assets/images/service-image.png" width="122" height="136" loading="lazy" alt="" class="img">

        <h2 class="h2 section-title">
          <span class="span">Tortas artesanales</span> sin colorantes ni conservantes.
        </h2>

        <ul class="grid-list">

          <li>
            <div class="service-card" data-aos="fade-right" data-aos-duration="1500">

              <figure class="card-icon">
                <img src="./assets/images/service-icon-1.png" width="70" height="70" loading="lazy" alt="service icon">
              </figure>

              <h3 class="h3 card-title">Horneamos el mismo día</h3>

              <p class="card-text">
                Haz tu pedido el día anterior para garantizar la entrega.
              </p>

            </div>
          </li>

          <li>
            <div class="service-card" data-aos="fade-up" data-aos-duration="1500">

              <figure class="card-icon">
                <img src="./assets/images/service-icon-2.png" width="70" height="70" loading="lazy" alt="service icon">
              </figure>

              <h3 class="h3 card-title">Alimento saludable</h3>

              <p class="card-text">
                No ultilizamos ningún colorante ni conservante.
              </p>

            </div>
          </li>

          <li>
            <div class="service-card" data-aos="fade-up" data-aos-duration="1500">

              <figure class="card-icon">
                <img src="./assets/images/service-icon-3.png" width="70" height="70" loading="lazy" alt="service icon">
              </figure>

              <h3 class="h3 card-title">Pagos seguros</h3>

              <p class="card-text">
                Utilizamos la pataforma de WOMPI (Bancolombia).
              </p>

            </div>
          </li>

          <li>
            <div class="service-card" data-aos="fade-left" data-aos-duration="1500">

              <figure class="card-icon">
                <img src="./assets/images/service-icon-4.png" width="70" height="70" loading="lazy" alt="service icon">
              </figure>

              <h3 class="h3 card-title">Asesoría Whatsapp</h3>

              <p class="card-text">
                Para un personalización mas efectiva, hablemos por Whatsapp.
              </p>

            </div>
          </li>

        </ul>

      </div>
    </section>


    <!--
    - #CTA
  -->

    <section class="cta has-bg-image" aria-label="cta" style="background-image: url('./assets/images/cta-bg.jpg')">
      <div class="container">

        <figure class="cta-banner">
          <img src="./assets/images/dog1.png" width="500" height="360" loading="lazy" alt="mona">
        </figure>

        <div class="cta-content">

          <img src="./assets/images/logoH.png" style="text-align: center;" width="250" height="35" loading="lazy"
            alt="taste guarantee" class="img">

          <h2 class="h2 section-title">Nuestras fiestas y tortas han hecho felices a los peludos por mas de 5 años!</h2>

          <p class="section-text fs-18">
            Estamos orgullosos de hacer felices a miles de Peludos y sus perripapás. Tratamos de dar la experiencia mas
            inolvidable para celebrar en cada fiesta y ayudar con la nutrición y salud de tu peludito.
          </p>
          <p class="section-text fs-18">No dudes en contactarnos por Whatsapp y personalizar la mejor de las fiestas de
            la forma mas soludable y
            llena de detalles.
          </p>
          <!--
          <a href="#" class="btn">Contáctanos</a> -->

        </div>

      </div>
    </section>

    <!--
    - #BRAND
  -->
    <!--
    <section class="section brand" aria-label="brand">
      <div class="container">

        <h2 class="h2 section-title">
          <span class="span">Popular</span> Brands
        </h2>

        <ul class="has-scrollbar">

          <li class="scrollbar-item">
            <div class="brand-card img-holder" style="--width: 150; --height: 150;">
              <img src="./assets/images/brand-1.jpg" width="150" height="150" loading="lazy" alt="brand logo"
                class="img-cover">
            </div>
          </li>

          <li class="scrollbar-item">
            <div class="brand-card img-holder" style="--width: 150; --height: 150;">
              <img src="./assets/images/brand-2.jpg" width="150" height="150" loading="lazy" alt="brand logo"
                class="img-cover">
            </div>
          </li>

          <li class="scrollbar-item">
            <div class="brand-card img-holder" style="--width: 150; --height: 150;">
              <img src="./assets/images/brand-3.jpg" width="150" height="150" loading="lazy" alt="brand logo"
                class="img-cover">
            </div>
          </li>

          <li class="scrollbar-item">
            <div class="brand-card img-holder" style="--width: 150; --height: 150;">
              <img src="./assets/images/brand-4.jpg" width="150" height="150" loading="lazy" alt="brand logo"
                class="img-cover">
            </div>
          </li>

          <li class="scrollbar-item">
            <div class="brand-card img-holder" style="--width: 150; --height: 150;">
              <img src="./assets/images/brand-5.jpg" width="150" height="150" loading="lazy" alt="brand logo"
                class="img-cover">
            </div>
          </li>

        </ul>

      </div>
    </section> -->

    <!-- # FAQ -->

    <!-- <div class="text-center">
      <h2 class="mt-5 mb-5">FAQ</h2>
    </div> -->

    <div class="container mt-5" id="faqs" data-aos="flip-right" data-aos-duration="1500">

      <!-- <img src="./assets/images/service-image.png" width="122" height="136" loading="lazy" alt="" class="img"> -->

      <h2 class="h2 section-title">
        <span class="span">Preguntas</span> frecuentes.
      </h2>

      <section class="container my-5" id="maincontent">
        <section id="accordion" data-aos="fade-up">
          <a class="py-4 d-block h-100 w-100 position-relative z-index-1 pr-1 text-secondary border-top"
            aria-controls="faq-17" aria-expanded="false" data-toggle="collapse" href="#faq-17" role="button">
            <div class="position-relative">
              <h2 class="m-0 pr-3">
                Hacen despachos nacionales?
              </h2>
              <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </a>
          <div class="collapse" id="faq-17" style=" color: #2250b2">
            <div class="card card-body border-0 p-5 fs-18">
              <p>Al ser un producto perecedero y al cuidado que tenemos al realizar nuestros productos y teniendo en cuenta
                que no usamos colorantes ni preservantes, no se pueden hacer despachos de varias horas o días de espera. Por ahora solo despachamos a Medellín y
                su area metropolitana.</p>
            </div>
          </div>

          <a class="py-3 d-block h-100 w-100 position-relative z-index-1 pr-1 text-secondary border-top"
            aria-controls="faq-18" aria-expanded="false" data-toggle="collapse" href="#faq-18" role="button">
            <div class="position-relative">
              <h2 class="m-0 pr-3">
                Que significa que son productos artesanales?
              </h2>
              <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </a>
          <div class="collapse" id="faq-18" style=" color: #2250b2">
            <div class="card card-body border-0 p-5 fs-18">
              <p>Todos nuestros productos son realizados el mismo dia de la entrega, ya que no contienen ningun tipo de conservante, colorante o anticompactante. Hechos a mano y con materias primas saludables para tu Peludo. en LA PASTELIDOG cuidamos la salud de nuestros peluditos.</p>
              <p>
              </p>
            </div>
          </div>

          <a class="py-3 d-block h-100 w-100 position-relative z-index-1 pr-1 text-secondary border-top"
            aria-controls="faq-19" aria-expanded="false" data-toggle="collapse" href="#faq-19" role="button">
            <div class="position-relative">
              <h2 class="m-0 pr-3">
                Cómo conservar los productos comprados ?
              </h2>
              <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </a>
          <div class="collapse" id="faq-19" style=" color: #2250b2">
            <div class="card card-body border-0 p-5 fs-18">
              <p>Al no tener conservantes, y además contener carne, la vida util de nuestros productos es corta y es necesaria la refrigeración una vez recibido el pedido. En caso de dejar parte del producto para el siguiente día, siempre debe ser conservado en refrigeración.</p>
              <p>
              </p>
            </div>
          </div>

          <a class="py-3 d-block h-100 w-100 position-relative z-index-1 pr-1 text-secondary  border-top"
            aria-controls="faq-20" aria-expanded="false" data-toggle="collapse" href="#faq-20" role="button">
            <div class="position-relative">
              <h2 class="m-0 pr-3">
                Hacen entregas de pedidos realzados el mismo día ?
              </h2>
              <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </a>
          <div class="collapse" id="faq-20" style=" color: #2250b2">
            <div class="card card-body border-0 p-5 fs-18">
              <p>Si, hacemos entregas el mismo día con un costo adicional de $15.000. Nuestra programación es realizada juiciosamente desde dias anteriores.</p>
              <p>
              </p>
            </div>
          </div>

          <a class="py-3 d-block h-100 w-100 position-relative z-index-1 pr-1 text-secondary  border-top"
            aria-controls="faq-21" aria-expanded="false" data-toggle="collapse" href="#faq-21" role="button">
            <div class="position-relative">
              <h2 class="m-0 pr-3">
                Puedo comprar solo los accesorios de la fiesta ?
              </h2>
              <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </a>
          <div class="collapse" id="faq-21" style=" color: #2250b2">
            <div class="card card-body border-0 p-5 fs-18">
              <p>Si, cada uno de nuestros productos se puede comprar de forma individual y tenemos una asesoría personalizada, siéntete en confianza de escribirnos a nuestro Whastapp y hacer tu pedido.</p>
              <p>
              </p>
            </div>
          </div>

          <a class="py-3 d-block h-100 w-100 position-relative z-index-1 pr-1 text-secondary  border-top"
            aria-controls="faq-22" aria-expanded="false" data-toggle="collapse" href="#faq-22" role="button">
            <div class="position-relative">
              <h2 class="m-0 pr-3">
                Porque no contestan llamadas al teléfono ?
              </h2>
              <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </a>
          <div class="collapse" id="faq-22" style=" color: #2250b2">
            <div class="card card-body border-0 p-5 fs-18">
              <p>Todos nuestros pedidos se relizan via Whatsapp para mayor eficacia en el servicio. Toda la asesoría,
                dudas y algún eventual reclamo, lo podemos solucionar por ese mismo canal.</p>
              <p>Síentete en confianza de escribirnos y resolver todas tus inquietudes.</p>
            </div>
          </div>
        </section>
      </section>

    </div>

  </article>
</main>
