import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { ServicesService } from '../../services/services.service';
import { CartService } from '../../services/cart.service';

@Component({
  standalone: true,
  selector: 'app-pago',
  templateUrl: './pago.page.html',
  styleUrls: ['./pago.page.scss'],
  imports: [RouterOutlet, RouterLink, NgFor, NgIf, CurrencyPipe]
})
export class PagoPage implements OnInit {

  idOrden = '';
  datos: any;
  aprobada = false;
  referencia = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private service: ServicesService,
    private cartService: CartService
  ) { }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {

      const id = params['id'];

      this.idOrden = id.replace('-', '').replace('-', '');

      // consultar la transaccion
      this.service.getTransaction(id).then(res => {
        if (res.error) { 
          this.aprobada = false;
        } else {          this.aprobada = (res.data.status === 'APPROVED');
          this.referencia = res.data.reference;
          // this.cartService.removeAllCartItems();
          // this.service.deleteVariable('pedido');
        }
      });

    });
  }

  returnToHome() {
    this.router.navigate(['/home'], { replaceUrl: true });
  }

}
